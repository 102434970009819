import React from 'react'

function MemberSales() {
  return (
    <div>
          Membership Sales New
    </div>
  )
}

export default MemberSales
