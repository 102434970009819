import { 
    
    USER_REGISTER_REQUEST, 
    USER_REGISTER_SUCCESS, 
    USER_REGISTER_FAIL,
    USER_LOGOUT, 
    
    USER_LIST_REQUEST, 
    USER_LIST_SUCCESS, 
    USER_LIST_FAIL,
    
    USER_LOGIN_REQUEST, 
    USER_LOGIN_SUCCESS, 
    USER_LOGIN_FAIL,

    USER_PTLIST_REQUEST,
    USER_PTLIST_SUCCESS,
    USER_PTLIST_FAIL

   
} from '../constants/userConstants'


export const userloginReducer = (state = {}, action) => {

    switch (action.type){

        case USER_LOGIN_REQUEST:
            return {loading: true}
        
        case USER_LOGIN_SUCCESS:
            return {loading: false, userInfo: action.payload }
        
        case USER_LOGIN_FAIL:
            return {loading: false, error: action.payload}
        
        case USER_LOGOUT:
            return {}
        
        default:
            return state


    }

}

export const userptlistreducer = (state = {ptusers: []}, action) => {

    switch(action.type){


        case USER_PTLIST_REQUEST:
            return {loading: true, ptusers: []}
        
        case USER_PTLIST_SUCCESS:
            return {loading:false, ptusers: action.payload}
        
        case USER_PTLIST_FAIL:
            return {loading: false, error: action.payload}
        
        
        default:
            return state

    }


}

export const userlistreducer = (state = {users: []}, action) => {

    switch(action.type){


        case USER_LIST_REQUEST:
            return {loading: true, users: []}
        
        case USER_LIST_SUCCESS:
            return {loading:false, users: action.payload}
        
        case USER_LIST_FAIL:
            return {loading: false, error: action.payload}
        
        
        default:
            return state

    }


}

export const userRegisterReducer = (state = {}, action) => {

    switch (action.type){

        case USER_REGISTER_REQUEST:
            return {loading: true}
        
        case USER_REGISTER_SUCCESS:
            return {loading: false, userInfo: action.payload }
        
        case USER_REGISTER_FAIL:
            return {loading: false, error: action.payload}
        
        case USER_LOGOUT:
            return {}
        
        default:
            return state


    }


}