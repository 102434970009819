export const MEMBER_CREATE_REQUEST = 'MEMBER_CREATE_REQUEST'
export const MEMBER_CREATE_SUCCESS = 'MEMBER_CREATE_SUCCESS'
export const MEMBER_CREATE_FAIL = 'MEMBER_CREATE_FAIL'


export const MEMBER_LIST_REQUEST = 'MEMBER_LIST_REQUEST'
export const MEMBER_LIST_SUCCESS = 'MEMBER_LIST_SUCCESS'
export const MEMBER_LIST_FAIL = 'MEMBER_LIST_FAIL'

export const MEMBER_PTLIST_REQUEST = 'MEMBER_PTLIST_REQUEST'
export const MEMBER_PTLIST_SUCCESS = 'MEMBER_PTLIST_SUCCESS'
export const MEMBER_PTLIST_FAIL = 'MEMBER_PTLIST_FAIL'

export const MEMBER_RESET = 'MEMBER_RESET';