export const CREATE_INVOICE_REQUEST = 'CREATE_INVOICE_REQUEST'
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS'
export const CREATE_INVOICE_FAIL = 'CREATE_INVOICE_FAIL'

export const TRANSACTION_LIST_REQUEST = 'TRANSACTION_LIST_REQUEST'
export const TRANSACTION_LIST_SUCCESS = 'TRANSACTION_LIST_SUCCESS'
export const TRANSACTION_LIST_FAIL = 'TRANSACTION_LIST_FAIL'

export const CREATE_PTASSIGN_REQUEST = 'CREATE_PTASSIGN_REQUEST'
export const CREATE_PTASSIGN_SUCCESS = 'CREATE_PTASSIGN_REQUEST'
export const CREATE_PTASSIGN_FAIL = 'CREATE_PTASSIGN_FAIL'

export const CREATE_REASSIGN_REQUEST = 'CREATE_REASSIGN_REQUEST'
export const CREATE_REASSIGN_SUCCESS = 'CREATE_REASSIGN_SUCCESS'
export const CREATE_REASSIGN_FAIL = 'CREATE_REASSIGN_FAIL'


export const COMMISSION_REPORT_REQUEST = 'COMMISSION_REPORT_REQUEST'
export const COMMISSION_REPORT_SUCCESS = 'COMMISSION_REPORT_SUCCESS'
export const COMMISSION_REPORT_FAIL = 'COMMISSION_REPORT_FAIL'

export const COMMISSION_DETAIL_REQUEST = 'COMMISSION_DETAIL_REQUEST'
export const COMMISSION_DETAIL_SUCCESS = 'COMMISSION_DETAIL_SUCCESS'
export const COMMISSION_DETAIL_FAIL = 'COMMISSION_DETAIL_FAIL'

export const SALES_REPORT_REQUEST = 'SALES_REPORT_REQUEST'
export const SALES_REPORT_SUCCESS = 'SALES_REPORT_SUCCESS'
export const SALES_REPORT_FAIL = 'SALES_REPORT_FAIL'

export const EXPIRED_SESSION_REQUEST = 'EXPIRED_SESSION_REQUEST'
export const EXPIRED_SESSION_SUCCESS = 'EXPIRED_SESSION_SUCCESS'
export const EXPIRED_SESSION_FAIL = 'EXPIRED_SESSION_FAIL'

export const EXTEND_SESSION_REQUEST = 'EXTEND_SESSION_REQUEST'
export const EXTEND_SESSION_SUCCESS = 'EXTEND_SESSION_SUCCESS'
export const EXTEND_SESSION_FAIL = 'EXTEND_SESSION_FAIL'

export const MEMBER_TRANSACTION_REQUEST = 'MEMBER_TRANSACTION_REQUEST'
export const MEMBER_TRANSACTION_SUCCESS = 'MEMBER_TRANSACTION_SUCCESS'
export const MEMBER_TRANSACTION_FAIL = 'MEMBER_TRANSACTION_FAIL'



